import { combineReducers } from 'redux'

import auth from './auth'

const appReducer = combineReducers({
  auth
})

export default function rootReducer (state, action) {
  return appReducer(state, action)
}
