import React from 'react'
import './index.scss'

class Button extends React.Component {

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className='button' onClick={this.props.onClick}>
        {this.props.text}
      </div>
    )
  }
}

Button.defaultProps = {
  text: 'Button',
  onClick: () => {alert('Not implemented yet...')}
}

export default Button