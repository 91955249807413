import React from 'react'
import { connect } from 'react-redux'
import { Router } from 'react-router-dom';
import axios from 'axios'

import { createInventory } from './actions/inventory'

import './App.scss'
import history from './services/history';
import Routes from './routes';
import NavigationBar from './shared/components/NavigationBar'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.setInventoryUidHeader = this.setInventoryUidHeader.bind(this)
  }

  setInventoryUidHeader(uid) {
    axios.defaults.headers.common['InventoryUid'] = uid
  }

  render() {
    let inventoryUid = localStorage.getItem('inventoryUid')
    if (inventoryUid === undefined || inventoryUid === null) {
      this.props.createInventory().then((res) => {
        inventoryUid = res.data
        localStorage.setItem('inventoryUid', inventoryUid)
        this.setInventoryUidHeader(inventoryUid)
      })
    } else {
      this.setInventoryUidHeader(inventoryUid)
    }
    return (
      <Router history={history}>
        <NavigationBar />
        <Routes />
      </Router>
    )
  }
}

export default connect(null, {
  createInventory
})(App)
