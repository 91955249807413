import React from 'react'
import { connect } from 'react-redux'
import './index.scss'
import Button from '../../elements/Button'

class WikiBox extends React.Component {

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    let imageClass = ''
    if (this.props.imageMaxHeight) imageClass = 'fit-item'
    return (
      <div className='wikibox-item'>
        <div className='wikibox-title'>
          {this.props.title}
        </div>
        {this.props.subTitle && (<div className='wikibox-title'>
          {this.props.subTitle}
        </div>)}
        <div className='wikibox-buttons'>
          {this.props.wikiUrl && (<Button text='Wiki' onClick={() => window.open(this.props.wikiUrl, "_blank")} />)}
          {this.props.extraButtons}
        </div>
        {this.props.imageUrl && (<div className='wikibox-image'>
          <img className={imageClass} src={this.props.imageUrl} alt='' />
        </div>)}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

Button.defaultProps = {
  title: null,
  subTitle: null,
  imageUrl: null,
  wikiUrl: undefined,
  extraButtons: undefined,
  imageMaxHeight: undefined
}

export default connect(mapStateToProps, {
})(WikiBox)