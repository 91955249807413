import React from 'react'
import { connect } from 'react-redux'
import './index.scss'
import WikiBox from '../../shared/components/WikiBox'
import { getCharacters } from '../../actions/characters'

class Characters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dealers: [],
      bosses: []
    }
  }

  componentDidMount() {
    this.props.getCharacters().then((res) => {
      this.setState({
        dealers: res.data.dealers,
        bosses: res.data.bosses
      })
    })
  }

  componentWillUnmount() {
  }

  renderCharacters (item, key) {
    return (
      <WikiBox
        key={key}
        title={item.name}
        imageUrl={item.imageUrl}
        wikiUrl={item.wikiUrl}
      />
    )
  }

  render() {
    return (
      <div className='page'>
        <div className='content-body'>
          <div className='characters'>
            <div>Dealers</div>
            <div className='characters-list'>
              {this.state.dealers.map(this.renderCharacters)}
            </div>
          </div>
          <div className='characters'>
            <div>Bosses</div>
            <div className='characters-list'>
              {this.state.bosses.map(this.renderCharacters)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, {
  getCharacters
})(Characters)