
import axios from 'axios'
import {
  API_BASE
} from '../constants'

export function createInventory () {
  let url = API_BASE + '/inventory/Create'
  return dispatch => {
    return axios.get(url)
  }
}