
import axios from 'axios'
import {
  API_BASE
} from '../constants'

export function getModules () {
  let url = API_BASE + '/hideout/'
  return dispatch => {
    return axios.get(url)
  }
}

export function changeLevel (data) {
  let url = API_BASE + '/hideout/ChangeLevel'
  return dispatch => {
    return axios.post(url, data)
  }
}

export function getRequirements (data) {
  let url = API_BASE + '/hideout/Requirements'
  return dispatch => {
    return axios.post(url, data)
  }
}
