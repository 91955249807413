import React from 'react'
import { connect } from 'react-redux'
import './index.scss'
import { getLocations } from '../../actions/locations'

class FrontPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { seconds: 0 }
  }

  tick() {
    this.setState(state => ({
      seconds: state.seconds + 1
    }))
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className='page'>
        <div className='content-body'>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, {
  getLocations
})(FrontPage)