import React from 'react'
import { connect } from 'react-redux'
import { getModules, getRequirements, changeLevel } from '../../actions/hideout'
import './index.scss'
import WikiBox from '../../shared/components/WikiBox'

class Hideout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modules: [],
      requirements: {
        levelRequirements: [],
        itemRequirements: []
      }
    }
    this.renderHideoutModule = this.renderHideoutModule.bind(this)
    this.changeModuleLevel = this.changeModuleLevel.bind(this)
    this.getRequirements = this.getRequirements.bind(this)
    this.renderRequirementItems = this.renderRequirementItems.bind(this)
  }

  componentDidMount() {
    this.props.getModules().then((res) => {
      this.setState({
        modules: res.data
      }, () => {
        this.getRequirements()
      })
    })
  }

  componentWillUnmount() {
  }

  changeModuleLevel(item, direction) {
    let level = item.currentLevel
    let oldLevel = item.currentLevel
    if (direction === '+') {
      level++
    } else if (direction === '-') {
      level--
    }
    if (level < 0) return null
    if (level > item.maxLevel) return null
    
    let changeLevelData = {
      HideoutModuleId: item.id,
      Level: level
    }
    const setLevel = (lvl) => {
      let modules = this.state.modules
      modules.map((m) => {
        if (m.id === item.id) m.currentLevel = lvl
      })
      this.setState({
        modules: modules
      })
    }

    this.props.changeLevel(changeLevelData).then((res) => {
      this.getRequirements()
    }, (err) => {
      setLevel(oldLevel)
    })
    setLevel(level)
  }

  renderHideoutModule(item, key) {
    return (
      <div key={key} className='hideoutmodule'>
        <div className='hideoutmodule-title'>
          {item.name}
        </div>
        <div className='hideoutmodule-counter'>
          <div className='btn' onClick={this.changeModuleLevel.bind(this, item, '-')}>-</div>
          <div>{item.currentLevel}</div>
          <div className='btn' onClick={this.changeModuleLevel.bind(this, item, '+')}>+</div>
        </div>
      </div>
    )
  }

  getRequirements() {
    let request = []
    this.state.modules.map((m) => {
      request.push({
        HideoutModuleId: m.id,
        Level: m.currentLevel
      })
    })
    this.props.getRequirements(request).then((res) => {
      this.setState({
        requirements: {
          levelRequirements: res.data.levelRequirements,
          itemRequirements: res.data.itemRequirements
        }
      })
    })
  }

  renderNumberDisplay(value) {
    return value.toLocaleString('da-DK');
  }

  renderRequirementItems(item, key) {
    return (
      <WikiBox 
        key={key}
        title={item.item.name}
        subTitle={this.renderNumberDisplay(item.count) + 'x'}
        wikiUrl={item.item.wikiUrl}
        imageUrl={item.item.imageUrl}
        imageMaxHeight
      />
    )
  }

  render() {
    return (
      <div className='page'>
        <div className='content-body'>
          {this.state.modules.map(this.renderHideoutModule)}
          <div className='requirements'>
            <div className='requirements-title'>Requirements</div>
            <div className='requirements-items'>
              {this.state.requirements.itemRequirements.map(this.renderRequirementItems)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, {
  getModules,
  changeLevel,
  getRequirements
})(Hideout)