import React from 'react'
import { connect } from 'react-redux'
import './index.scss'

class LocationKeys extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLocationId: 0
    }
  }

  componentDidMount() {
    this.setState({
      selectedLocationId: this.props.match.params.locationId
    })
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className='page'>
        <div className='content-body'>
          {this.state.selectedLocationId}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, {
})(LocationKeys)