
import axios from 'axios'
import {
  API_BASE
} from '../constants'

export function getCharacters () {
  let url = API_BASE + '/character/'
  return dispatch => {
    return axios.get(url)
  }
}