import React from 'react'
import { Switch, Route } from 'react-router-dom'
import FrontPage from '../pages/FrontPage'
import Locations from '../pages/Locations'
import LocationKeys from '../pages/Locations/LocationKeys'
import Items from '../pages/Items'
import Characters from '../pages/Characters'
import Quests from '../pages/Quests'
import Hideout from '../pages/Hideout'
import Wishlist from '../pages/Wishlist'

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={FrontPage} />
      <Route path="/locations" exact component={Locations} />
      <Route path="/locations/:locationId/keys" component={LocationKeys} />
      <Route path="/items" exact component={Items} />
      <Route path="/characters" exact component={Characters} />
      <Route path="/quests" exact component={Quests} />
      <Route path="/hideout" exact component={Hideout} />
      <Route path="/wishlist" exact component={Wishlist} />
    </Switch>
  )
}
