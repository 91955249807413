import React from 'react'
import './index.scss'
import Button from '../../elements/Button'
import history from '../../../services/history'

class NavigationBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navbarOpen: false
    }
    this.redirectTo = this.redirectTo.bind(this)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  navbarOnClick(open) {
    this.setState({
      navbarOpen: open
    })
  }

  redirectTo (page) {
    this.navbarOnClick(false)
    history.push(page)
  }

  render() {
    let navbarClass = ''
    if (this.state.navbarOpen) navbarClass = 'responsive'
    return (
      <div className='navbar' >
        <div className='navbar-icon' onClick={this.redirectTo.bind(this, '/')} >
          TarkovAPP
        </div>
        <div className={'navbar-buttons ' + navbarClass}>
          <div className='fa fa-bars' onClick={this.navbarOnClick.bind(this, !this.state.navbarOpen)} />
          <Button text='Locations' onClick={this.redirectTo.bind(this, '/locations') } />
          <Button text='Characters' onClick={this.redirectTo.bind(this, '/characters') } />
          <Button text='Items' onClick={this.redirectTo.bind(this, '/items') } />
          <Button text='Quests' onClick={this.redirectTo.bind(this, '/quests') } />
          <Button text='Hideout' onClick={this.redirectTo.bind(this, '/hideout') } />
          <Button text='Wishlist' onClick={this.redirectTo.bind(this, '/wishlist') } />
        </div>
        <div className='navbar-profile'>
          Mols
        </div>
      </div>
    )
  }
}

export default NavigationBar