import React from 'react'
import { connect } from 'react-redux'
import './index.scss'
import WikiBox from '../../shared/components/WikiBox'
import Button from '../../shared/elements/Button'
import { getLocations } from '../../actions/locations'
import history from '../../services/history'

class Locations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: []
    }
  }

  componentDidMount() {
    this.props.getLocations().then((res) => {
      this.setState({
        locations: res.data
      })
    })
  }

  componentWillUnmount() {
  }

  renderLocations (item, key) {
    let extraButtons = [
      <Button text='Keys' onClick={() => history.push('/locations/' + item.id + '/keys')} />
    ]
    return (
      <WikiBox
        key={key}
        title={item.name}
        imageUrl={item.imageUrl}
        wikiUrl={item.wikiUrl}
        extraButtons={extraButtons}
      />
    )
  }

  render() {
    return (
      <div className='page'>
        <div className='content-body'>
          {this.state.locations.map(this.renderLocations)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, {
  getLocations
})(Locations)